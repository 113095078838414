<template>
    <div>
        <ProductsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Products</h1>

            <h4>Curbs - when you need them - We're very proud of our track record!</h4>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/cdi_products.jpg`" class="img-responsive img_content_right">

            <p>
                For our customers and the users of our products to be happy with our products and service, we have always felt that there are 4 factors that will contribute to that happiness.
            </p>

            <ol class="list_content">
                <li>Did the product ship when they were told?</li>
                <li>Did the product deliver when they were told?</li>
                <li>Did the product show up undamaged?</li>
                <li>Did the product work once it was installed?</li>
            </ol>

            <p>
                We have set a goal for each item and have been tracking these 4 points on a weekly basis for many years. This allows us to make changes instantly if any one of them were to get off track.
            </p>

            <ol class="list_content">
                <li>% Ship on time = 99.3% (Goal = 99.0%)</li>
                <li>% Delivered on time = 98% (Goal = 94.0%)</li>
                <li>% Damaged in transit = &lt;0.5% (Goal = 1.0%)</li>
                <li>% Failures in the field = 0 (Goal = 0)</li>
            </ol>
        </main>
    </div>
</template>

<script>
import ProductsNav from '@/components/content/ProductsNav.vue'
export default {
    name: "Products",
    components: {
        ProductsNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Curb Adapter Products']);
    },
}
</script>

<style scoped>

</style>