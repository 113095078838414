<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul class="nav nav-pills nav-stacked affix" >
                <li class="cdi_tab"><router-link to="/customcurbs">Curbs</router-link></li>
                <li class="cdi_tab"><router-link to="/conforming">Conforming / Seismic Curbs</router-link></li>
                <li class="cdi_tab"><router-link to="/isolation">Isolation Curbs</router-link></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "ProductsNav",
}
</script>

<style scoped>

</style>